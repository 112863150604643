import React from 'react';
import Slider from 'react-slick';
import { PhotoSwipe } from 'react-photoswipe';
import 'slick-carousel/slick/slick.css';
import 'react-photoswipe/lib/photoswipe.css';

// TODO: remove
const MediaSizes = {
	lg: '1024px'
}

class Gallery extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isOpen: false,
			mounted: false
		};
		
		this.openGallery = this.openGallery.bind(this);
		this.closeGallery = this.closeGallery.bind(this);
	}
	
	componentDidMount() {
		this.initSlick();
		this.initPhotoSwipe();
		
		this.setState({
			mounted: true
		});
	}
	
	initSlick() {
		const { images, maxThumbs, position, styles: { SlideArrow, Pagination, Dot, ShowMore } } = this.props;
		
		this.slick = {
			draggable: false,
			adaptiveHeight: true,
			prevArrow: <SlideArrow prev />,
			nextArrow: <SlideArrow />,
			arrows: images.length > 1,
			infinite: images.length > 1,
			dots: images.length > 1,
			className: 'pdp-slider',
			responsive: [
				{
					breakpoint: MediaSizes.lg,
					settings: {
						draggable: true
					}
				}
			],
			appendDots: dots => (
				<Pagination position={position}>
					{dots}
					{dots.length > maxThumbs && <li><ShowMore data-index={maxThumbs} onClick={this.openGallery}>Vis mere</ShowMore></li>}
				</Pagination>
			),
			customPaging: i => (
				<Dot image={images[i].thumbnail} hideDotOnDesktop={i >= maxThumbs ? true : false} />
			),
			afterChange: i => {
				this.photoSwipe.index = i;
			}
		};
	}
	
	initPhotoSwipe() {
		this.photoSwipe = {
			index: 0,
			bgOpacity: 0.9,
			closeOnScroll: false,
			fullscreenEl: false,
			shareEl: false
		};
	}
	
	openGallery(event, index) {
		if (typeof index === 'number') {
			this.photoSwipe.index = index;
		}
		else if (event.target.dataset.index) {
			this.photoSwipe.index = Number(event.target.dataset.index);
		}
		
		this.setState({
			isOpen: true
		});
	}
	
	closeGallery() {
		this.setState({
			isOpen: false
		});
	}
	
	render() {
		// const { Content } = this.props.subComponents;
		const { images, position, styles: { Wrapper, Slide, Spinner, GlobalStyle } } = this.props;
		
		return (
			<Wrapper>
				{this.state.mounted && images && images.length > 0 ? (
					<>
						<GlobalStyle position={position} />
						
						<Slider ref={slider => this.slider = slider} {...this.slick}>
							{images.map((image, i) => (
								<Slide key={i}>
									<img src={image.src} alt={image.title} data-index={i} onClick={this.openGallery} />
								</Slide>
							))}
						</Slider>
						
						<PhotoSwipe items={images} options={this.photoSwipe} isOpen={this.state.isOpen} onClose={this.closeGallery} />
					</>
				) : (
					<Spinner />
				)}
			</Wrapper>
		)
	}
}

export default Gallery;