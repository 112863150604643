import React from 'react';
// import { connect } from 'react-redux';
// import { closeModal } from '../store/actions';
import styled from 'styled-components';
import ScrollLock from 'react-scrolllock';
import Default from './types/Default';

// const flex = {
// 	align: {
// 		center: 'center',
// 		top: 'flex-start',
// 		bottom: 'flex-end',
// 		left: 'stretch',
// 		right: 'stretch'
// 	},
// 	justify: {
// 		center: 'center',
// 		top: 'center',
// 		bottom: 'center',
// 		left: 'flex-start',
// 		right: 'flex-end'
// 	}
// }

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: ${props => props.align ? props.align : 'center'};
	justify-content: ${props => props.justify ? props.justify : 'center'};
`

const Overlay = styled.div`
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, 0.5);
	position: absolute;
`

const Box = styled.div`
	width: ${props => props.width || 'auto'};
	max-width: 100%;
	max-height: 100%;
	background-color: #fff;
	position: relative;
	display: flex;
`

const Close = styled.button`
	padding: 10px;
	width: 40px;
	height: 40px;
	background-color: transparent;
	border: 0;
	outline: 0;
	position: absolute;
	top: 0;
	right: 0;
	appearance: none;
	cursor: pointer;
	transition: 200ms;
	
	&:hover {
		opacity: 0.5;
	}
	
	&::before,
	&::after {
		content: "";
		width: 20px;
		height: 2px;
		background-color: #000;
		display: block;
	}
	&::before { transform: translateY(1px) rotate(-45deg); }
	&::after { transform: translateY(-1px) rotate(45deg); }
`

const Main = styled.div`
	overflow: auto;
`

class Modal extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			mounted: false
		};
		
		this.scrollArea = React.createRef();
	}
	
	componentDidMount() {
		this.setState({
			mounted: true
		});
	}
	
	render() {
		const { modal } = this.props;
		
		return modal && (
			<Wrapper align={null} justify={null}>
				<Overlay onClick={this.props.closeModal} />
				
				<Box>
					<Close onClick={this.props.closeModal} />
					
					<Main ref={this.scrollArea}>
						<Default />
					</Main>
				</Box>
				
				{this.scrollArea.current && <ScrollLock touchScrollTarget={this.scrollArea.current} />}
			</Wrapper>
		);
	}
}

export default Modal;
