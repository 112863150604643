import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './store/reducer';
import MainStyling from './styling/Main';
import App from './App';

// Pages
import Home from './pages/Home';
import Modal from './pages/Modal';
import Fetch from './pages/Fetch';
import Rss from './pages/rss/Rss';
import AccordionTabs from './pages/AccordionTabs';
import DataGrid from './pages/DataGrid';
import Form from './pages/Form';
import Media from './pages/Media';
import Hero from './pages/Hero';

const store = createStore(reducer);

ReactDOM.render(
	<Provider store={store}>
		<MainStyling>
			<BrowserRouter>
				<App>
					<Route exact path="/" component={Home} />
					<Route path="/accordiontabs" component={AccordionTabs} />
					<Route path="/modal" component={Modal} />
					<Route path="/fetch" component={Fetch} />
					<Route path="/rss" component={Rss} />
					<Route path="/datagrid" component={DataGrid} />
					<Route path="/form" component={Form} />
					<Route path="/media" component={Media} />
					<Route path="/hero" component={Hero} />
				</App>
			</BrowserRouter>
		</MainStyling>
	</Provider>,
	document.getElementById('root')
);
