import React from 'react';
import styled, {css, keyframes} from 'styled-components';
import {Link} from 'react-router-dom';

const ButtonStyle = css`
	padding: 0;
	width: 100%;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	outline: 0;
	display: flex;
	align-items: center;
	color: inherit;
	font: inherit;
	appearance: none;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	
	@media(min-width: ${props => props.breakpoint}) {
		width: auto;
		justify-content: center;
	}
`

const BasicButton = styled.button``
const BasicLink = styled.a``

const Button = styled(({href, to, active, ...props}) => href ? <BasicLink href={href} target="_blank" {...props} /> : to ? <Link to={to} {...props} /> : <BasicButton {...props} />)`
	${ButtonStyle}
`

const Wrapper = styled.div``

const Tabs = styled.div`
	display: none;
	
	@media(min-width: ${props => props.breakpoint}) {
		display: flex;
	}
`

const Tab = styled(Button)`
	display: none;
	
	@media(min-width: ${props => props.breakpoint}) {
		display: flex;
	}
`

const Accordion = styled(Button)`
	@media(min-width: ${props => props.breakpoint}) {
		display: none;
	}
`

const Heading = styled.span``

const Icon = styled.img``

const Arrow = styled.div`
	margin-left: auto;
	width: 9px;
	height: 9px;
	border-right: 1px solid currentColor;
	border-bottom: 1px solid currentColor;
	transform: translate(-50%, -25%) rotate(${props => props.active ? '-135deg' : '45deg'});
	transform-origin: 75% 75%;
	box-sizing: border-box;
	
	@media(min-width: ${props => props.breakpoint}) {
		display: none;
	}
`

const Panels = styled.div``

const Panel = styled.div``

const Content = styled.div`
	display: ${props => props.active ? 'block' : 'none'};
`

const spin = keyframes`
	100% { transform: rotate(360deg); }
`

const Spinner = styled.div`
	margin: auto;
	width: 40px;
	height: 40px;
	border: 3px solid rgba(0,0,0, 0.2);
	border-top-color: #000;
	border-radius: 50%;
	animation: ${spin} 1s linear infinite;
`

const styles = {
	Wrapper,
	Tabs,
	Tab,
	Accordion,
	Heading,
	Icon,
	Arrow,
	Panels,
	Panel,
	Content,
	Spinner
};

export default styles;
