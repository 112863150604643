import { css } from 'styled-components';

const main = css`
	html { box-sizing: border-box; }
	*, *:before, *:after { box-sizing: inherit; }
	
	${'' /* ::selection {
		background: ${props => props.theme.color.primary};
		color: #fff;
	} */}
	
	${'' /* ::-moz-selection {
		background: ${props => props.theme.color.primary};
		color: #fff;
	} */}
	
	body {
		background-color: ${props => props.theme.color.light};
		color: ${props => props.theme.color.text};
		line-height: 1.3;
	}
	
	h1 {
		font-weight: 700;
		font-size: 40px;
		color: ${props => props.theme.color.heading};
	}
	
	h2 {
		font-weight: 400;
		font-size: 24px;
		color: ${props => props.theme.color.heading};
	}
	
	button {
		padding: 10px 40px;
		background-color: ${props => props.theme.color.primary};
		border-radius: 4px;
		outline: 0;
		color: white;
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
	}
`

export default main;
