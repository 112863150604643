import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

/*
{
	shipping: {
		address: '',
		days: [
			'monday',
			'tuesday',
			'wednesday'
		]
	},
	billing: {
		address: ''
	},
	genders: [
		'male',
		'female',
		'none'
	]
}

shipping.address = ''
shipping.days = 'monday'
shipping.days = 'tuesday'
shipping.days = 'wednesday'
billing.address = ''
genders = 'male'
genders = 'female'
genders = 'none'

*/

export function formData(form) {
	const formData = new FormData(form);
	const data = {};

	for (const elem of formData.entries()) {
		const [ name, value ] = elem;
		const group = name.split('.');

		if (group.length > 1) {
			if (!data[group[0]]) {
				data[group[0]] = {};
			}
			data[group[0]][group[1]] = value;
		} else if (data[name]) {
			if (typeof data[name] === 'string') {
				data[name] = [data[name], value];
			} else {
				data[name].push(value);
			}
		} else {
			data[name] = value;
		}
	}

	return data;
}

const Wrapper = styled.div`
	margin-bottom: 30px;
	position: relative;

	${props =>
		props.error !== undefined &&
		css`
			input,
			select,
			textarea {
				border-color: ${props => (props.error ? props => props.theme.color.error : props.theme.color.success)};
			}
		`}
`;

const Error = styled.p`
	margin-top: 0.5em;
	color: ${props => props.theme.color.error};
	font-size: 12px;
	position: absolute;
	top: 100%;
`;

const InputStyled = styled.input`
	padding: 10px;
	width: 100%;
	border: 1px solid ${props => props.theme.color.border};
	border-radius: 4px;
	outline: 0;
	display: block;
	font-size: inherit;

	&:focus {
		border-color: ${props => props.theme.color.primary};
	}
`;

const Label = styled.label`
	margin-bottom: 0.5em;
	font-weight: 700;
	font-size: 14px;
	text-transform: uppercase;
	display: block;
`;

const SelectStyled = styled.select`
	padding: 10px;
	width: 100%;
	border: 1px solid ${props => props.theme.color.border};
	border-radius: 4px;
	outline: 0;
	display: block;
	font-size: inherit;

	&:focus {
		border-color: ${props => props.theme.color.primary};
	}
`;

const checkboxStyle = css`
	border-radius: 4px;

	&:checked {
		&:after {
			content: '';
			width: 7px;
			height: 11px;
			border-right: 3px solid ${props => props.theme.color.primary};
			border-bottom: 3px solid ${props => props.theme.color.primary};
			display: block;
			transform: translate(6px, 2px) rotate(45deg);
		}
	}
`;

const radioStyle = css`
	padding: 4px;
	border-radius: 50%;
	background-clip: content-box;

	&:checked {
		background-color: ${props => props.theme.color.primary};
	}
`;

const ChoiceWrapper = styled.div`
	display: flex;
	align-items: center;
	user-select: none;

	& + & {
		margin-top: 7px;
	}

	${props =>
		props.box &&
		css`
			input {
				display: none;
			}

			input:checked + label {
				background-color: red;
			}

			label {
				padding: 20px;
				width: 100%;
				background-color: #eee;
			}
		`}
`;

const ChoiceInput = styled.input`
	margin: 0 0.5em 0 0;
	width: 20px;
	height: 20px;
	background-color: white;
	border: 1px solid ${props => props.theme.color.border};
	outline: 0;
	appearance: none;

	&:focus {
		border-color: ${props => props.theme.color.primary};
	}

	${props => (props.type === 'radio' ? radioStyle : checkboxStyle)};
`;

const ChoiceLabel = styled.label``;

const FormStyled = styled.form``;

const ThemeContext = React.createContext();

// export class FormHelper extends React.Component {
// 	constructor(props) {
// 		super(props);

// 		console.log('formhelper', props, ThemeContext)
// 	}

// 	static contextType = ThemeContext;

// 	render() {
// 		const { blurHandler } = this.props;

// 		console.log('form', this.props)

// 		return (
// 			<ThemeContext.Provider value={{ blurHandler }}>
// 				<FormStyled {...this.props} />
// 			</ThemeContext.Provider>
// 		);
// 	};
// };

export const FormHelper = props => {
	const { blurHandler, errors } = props;

	// console.log('form', props)

	return (
		<ThemeContext.Provider value={{ blurHandler, errors }}>
			<FormStyled {...props} />
		</ThemeContext.Provider>
	);
};

export const Group = props => {
	const { children, ...rest } = props;
	const error = props.errors && props.errors[props.name];

	const context = useContext(ThemeContext);

	return (
		<Wrapper error={error}>
			{props.label && <Label>{props.label}</Label>}

			{React.Children.map(children, child => (child.type === Field ? React.cloneElement(child, { ...rest, ...child.props }) : child))}

			{error && <Error>{error}</Error>}
		</Wrapper>
	);
};

export const Field = props => {
	const context = useContext(ThemeContext);

	switch (props.type) {
		case 'radio':
		case 'checkbox':
			return <Choice onBlur={context.blurHandler} errors={context.errors} {...props} />;
		case 'select':
			return <Select onBlur={context.blurHandler} errors={context.errors} {...props} />;
		default:
			return <Input onBlur={context.blurHandler} errors={context.errors} {...props} />;
	}
};

export const Input = props => {
	const error = props.errors && props.errors[props.name];

	return (
		<Wrapper error={error}>
			{props.label && <Label>{props.label}</Label>}
			<InputStyled {...props} />
			{error && <Error>{error}</Error>}
		</Wrapper>
	);
};

export const Choice = props => {
	return (
		<ChoiceWrapper box={props.box}>
			<ChoiceInput {...props} />
			<ChoiceLabel htmlFor={props.id}>{props.label}</ChoiceLabel>
		</ChoiceWrapper>
	);
};

export const ChoiceGroup = props => {
	const { type, name, onBlur, box } = props;
	const children = React.Children.map(props.children, child => React.cloneElement(child, { type, name, onBlur, box, ...child.props }));
	const error = props.errors && props.errors[props.name];

	return (
		<Wrapper error={error}>
			{props.label && <Label>{props.label}</Label>}
			{children}
			{error && <Error>{error}</Error>}
		</Wrapper>
	);
};

export const Select = props => {
	const error = props.errors && props.errors[props.name];

	return (
		<Wrapper error={error}>
			{props.label && <Label>{props.label}</Label>}
			<SelectStyled {...props}>
				{/* {props.options.map((item, i) => (
					<option value={item[1]} key={i}>{item[0]}</option>
				))} */}
			</SelectStyled>
			{error && <Error>{error}</Error>}
		</Wrapper>
	);
};
