import React from 'react';
import styled from 'styled-components';
import { parseString } from 'xml2js';
import Moment from 'react-moment';

/* eslint-disable */

const Wrapper = styled.article`
	padding: 40px;
	width: 33.33%;
`

const Heading = styled.h1`
	margin-bottom: 20px;
	padding-left: 70px;
	color: #aaa;
	font-weight: 400;
	font-size: 24px;
`

const List = styled.ul`
	
`

const Item = styled.li`
	
`

const Link = styled.a`
	padding: 10px 0;
	display: flex;
	transition: 200ms;
	
	&:hover {
		color: ${props => props.theme.color.primary};
	}
`

const PubDate = styled(Moment)`
	min-width: 70px;
	color: #aaa;
`

const Title = styled.h3`
	font-weight: 400;
`

const Description = styled.p`
	display: none;
`

class FeedClass extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			heading: '',
			description: '',
			items: []
		};
		
		this.getFeed();
		
		this.getFeed = this.getFeed.bind(this);
	}
	
	getFeed() {
		fetch(this.props.url, {
			headers: {
				'accept': 'application/json'
			}
		})
		.then(response => {
			if (!response.ok) {
				throw new Error('Bad response');
			}
			return response.text();
		})
		.then(text => {
			parseString(text, (err, xml) => {
				this.setState({
					heading: xml.rss.channel[0].title,
					items: xml.rss.channel[0].item
				});
			});
		})
		.catch(error => {
			console.log(error);
		});
	}
	
	stripHtml(htmlString) {
		const tempElem = document.createElement("div");
		tempElem.innerHTML = htmlString;
		const images = tempElem.getElementsByTagName('img');
		
		let elements = {
			image: {
				src: images[0].src,
				alt: images[0].alt
			},
			description: tempElem.textContent || tempElem.innerText || ''
		}
		
		return elements;
	}
	
	render() {
		const { heading, items } = this.state;
		
		return (
			<Wrapper>
				<Heading>{heading}</Heading>
				
				<List>
					{items.map((item, i) => {
						return (
							<Item key={i}>
								<Link href={item.link[0]}>
									<PubDate format="DD/MM">{item.pubDate[0]}</PubDate>
									<Title>{item.title}</Title>
									{/* <div dangerouslySetInnerHTML={{__html: item.description}} /> */}
									{/* <Description>{this.stripHtml(item.description)}</Description> */}
								</Link>
							</Item>
						)
					})}
				</List>
			</Wrapper>
		);
	}
}

export default FeedClass;
