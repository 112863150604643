import React from 'react';
import styled from 'styled-components';
import Feed from './Feed';

/* eslint-disable */

const Wrapper = styled.div`
	display: flex;
`

class RssClass extends React.Component {
	render() {
		return (
			<Wrapper>
				<Feed url="https://feeds.tv2.dk/nyhederne_seneste/rss" />
				<Feed url="https://feeds.tv2.dk/vejret_seneste/rss" />
				<Feed url="https://feeds.tv2.dk/beep_seneste/rss" />
			</Wrapper>
		);
	}
}

export default RssClass;
