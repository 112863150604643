import React from 'react';
import styled from 'styled-components';
// import Table from './table/Table';
// const ReactDataGrid = require('react-data-grid');

const Wrapper = styled.div`
	overflow: auto;
`

class DataGrid extends React.Component {
	constructor(props) {
		super(props);
		
		// const rows = [
		// 	{
		// 		one: '1',
		// 		two: '2',
		// 		three: '3'
		// 	},
		// 	{
		// 		one: '11',
		// 		two: '22',
		// 		three: '33'
		// 	}
		// ];
		//
		// const columns = [
		// 	{ key: 'one', name: 'One' },
		// 	{ key: 'two', name: 'Two', sortable: true },
		// 	{ key: 'm1', name: 'mmmmm' },
		// 	{ key: 'm2', name: 'mmmmm' },
		// 	{ key: 'm3', name: 'mmmmm', sortable: true },
		// 	{ key: 'm4', name: 'mmmmm' },
		// 	{ key: 'm5', name: 'mmmmm' },
		// 	{ key: 'm6', name: 'mmmmm' },
		// 	{ key: 'm7', name: 'mmmmm' },
		// ];
		
		const columns = [
			{
				key: 'id',
				name: 'ID'
			},
			{
				key: 'title',
				name: 'Title'
			}
		];
		
		const rows = [
			{
				id: 1,
				title: 'Title 1'
			},
			{
				id: 2,
				title: 'Title 2'
			},
			{
				id: 3,
				title: 'Title 3'
			}
		];
		
		this.state = {
			rows: rows,
			columns: columns
		};
	}
	
	fetchDate(data) {
		console.log(data)
	}
	
	render() {
		return (
			<>
				<Wrapper>
					DATAGRID
					{/* <ReactDataGrid
						columns={this.state.columns}
						rowGetter={i => this.state.rows[i]}
						rowsCount={this.state.rows.length}
						rowSelection={false}
					/> */}
					{/* <Table rows={this.state.rows} columns={this.state.columns} update={this.fetchDate} /> */}
				</Wrapper>
			</>
		);
	}
}

export default DataGrid;
