import { combineReducers } from 'redux';

const modal = (state = null, action) => {
	switch (action.type) {
		case 'OPEN_MODAL':
			return {
				modal: action.modal
			};
		case 'CLOSE_MODAL':
			return null;
		default:
			return state;
	}
};

const spinner = (state = {}, action) => {
	switch (action.type) {
		case 'SHOW_SPINNER':
			return true
			
		case 'HIDE_SPINNER':
			return false;
			
		default:
			return false;
	}
};

const reducer = combineReducers({
	modal,
	spinner
});

export default reducer;
