import React from 'react';
import { AccordionTabs } from '../components/hesehus-react-accordiontabs';
import accordionTabsStyles from '../styling/components/accordionTabs';
import star from '../assets/star.svg';

const MyComponent = (props) => {
	console.log(props)
	return (
		<div>
			MyComponent
			<input type="text" />
		</div>
	);
}

class AccordionTabsClass extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			loading: true
		}
		
		this.toggleLoading = this.toggleLoading.bind(this);
	}
	
	toggleLoading() {
		this.setState({
			loading: !this.state.loading
		});
	}
	
	/*
	Fetch:
	url ('/internal/posts')
	content: <Component />
	
	Link internal:
	url ('/about')
	
	Link external:
	url ('about.dk')
	
	Callbacks:
	onClick
	onLoaded
	
	heading:elem,
	content:elem,
	icon:elem,
	loading:boolean,
	active:boolean,
	url:string,
	pathname:string,
	onClick:func,
	onLoaded:func,
	*/
	
	render() {
		const items = [
			{
				active: true,
				heading: 'Text',
				content: 'Content',
				icon: star,
				path: '/tab/text'
			},
			{
				heading: 'Component',
				content: <MyComponent hello="Loaded" />,
				path: '?tab=component'
			},
			{
				heading: 'Fetch',
				content: <MyComponent hello="Fetched" />,
				fetch: 'https://jsonplaceholder.typicode.com/posts/1'
			},
			{
				heading: 'Loading',
				content: 'Done fake loading',
				loading: this.state.loading,
			},
			{
				heading: 'Internal',
				to: '/rss'
			},
			{
				heading: 'External',
				href: 'http://www.google.dk'
			}
		];
		
		return (
			<div>
				<button onClick={this.toggleLoading}>Toggle loading</button>
				<br /><br />
				<AccordionTabs items={items} breakpoint="1000px" styles={accordionTabsStyles} onCallback={this.callback} />
			</div>
		);
	}
}

export default AccordionTabsClass;
