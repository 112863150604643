export const openModal = ({ type = 'default', props = {}, position = 'center', width = null } = {}) => {
	return {
		type: 'OPEN_MODAL',
		modal: {
			type,
			props,
			position,
			width
		}
	};
};

export const closeModal = () => {
	return {
		type: 'CLOSE_MODAL'
	};
};

export const showSpinner = () => {
	console.log('showSpinner')
	return {
		type: 'SHOW_SPINNER'
	};
};

export const hideSpinner = () => {
	console.log('hideSpinner')
	return {
		type: 'HIDE_SPINNER'
	};
};
