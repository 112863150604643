import React from 'react';
import styled from 'styled-components';

/* eslint-disable */

const Wrapper = styled.div`
	text-align: center;
`

const Heading = styled.h1`
	margin-bottom: 0.5em;
	font-size: 40px;
`

const Text = styled.p`
	
`

class Fetch extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			loading: false,
			error: null
		};
		
		this.notFound = this.notFound.bind(this);
		this.internalServerError = this.internalServerError.bind(this);
	}
	
	fetch(url) {
		// https://www.mocky.io/
		
		// this.setState({
		// 	loading: true
		// });
		
		var serverResponse = null;
		
		const options = {
			headers: {
				'Accept': 'application/json'
			}
		}
		
		fetch(url, options)
		.then(response => {
			serverResponse = response;
			
			if (response.ok || response.status === 500) {
				return response.json();
			}
			
			throw null;
		})
		.then(json => {
			if (serverResponse.status === 500) {
				throw json;
			}
			
			console.log('Success', json);
			
			this.setState({
				loading: false
			});
		})
		.catch(err => {
			console.warn('Error', err)
			console.warn('Response', serverResponse)
			
			var error = null;
			
			try {
				error = {
					heading: err && err.pageName ? err.pageName : serverResponse.status,
					text: err && typeof(err.success) === 'boolean' ? String(err.success) : serverResponse.statusText
				}
			}
			catch(e) {
				console.error(e)
				
				error = {
					heading: e.name,
					text: e.message
				};
			}
			
			this.setState({
				loading: false,
				error: error
			});
		});
	}
	
	notFound() {
		this.fetch('http://www.mocky.io/v2/5bdb5bcc3200001b2a3ad545')
	}
	
	internalServerError() {
		this.fetch('http://www.mocky.io/v2/5bdb5bad3200009e243ad544')
	}
	
	render() {
		const { loading, error } = this.state;
		
		if (loading) {
			return 'LOADING';
		}
		
		return (
			<Wrapper>
				<div>
					<button onClick={this.notFound}>404</button>
					<button onClick={this.internalServerError}>500</button>
				</div>
				
				{error ? (
					<>
						<Heading>{error.heading}</Heading>
						<Text>{error.text}</Text>
					</>
				) : (
					<Heading>No Errors</Heading>
				)}
			</Wrapper>
		);
	}
}

export default Fetch;
