import React from 'react';

class ContentClass extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			content: props.content,
			loading: props.loading ? props.loading : props.fetch ? true : false
		};
		
		this.fetched = false;
		this.fetch = this.fetch.bind(this);
		
		// fetch if active and not fetched
		if (props.fetch && props.active && !this.fetched) {
			this.fetch(props.fetch);
		}
	}
	
	componentDidUpdate(prevProps) {
		// fetch if active and not fetched
		if (this.props.fetch && this.props.active && !this.fetched) {
			this.fetch(this.props.fetch);
		}
		
		// update loading state, if using loading prop
		if (prevProps.loading !== this.props.loading) {
			this.setState({
				loading: this.props.loading
			});
		}
	}
	
	fetch(url) {
		// only fetch once
		this.fetched = true;
		
		fetch(url, {
			credentials: 'same-origin',
			headers: {
				'accept': 'application/json'
			}
		})
		.then(response => {
			if (!response.ok) {
				throw new Error('Bad response');
			}
			return response.json();
		})
		.then(json => {
			// clone the passed component, so we can pass json
			const content = React.cloneElement(this.state.content, {data: json});
			
			this.setState({
				content: content,
				loading: false
			});
		})
		.catch(error => {
			console.warn(error);
		});
	}
	
	render() {
		const { Content, Spinner } = this.props.styles;
		
		return (
			<Content breakpoint={this.props.breakpoint} active={this.props.active}>
				{this.props.active && (
					this.state.loading ? <Spinner /> : this.state.content
				)}
			</Content>
		);
	}
}

export default ContentClass;
