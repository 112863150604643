const color = {
	primary: '#1991EB',
	secondary: '#09C199',
	tertiary: '#F7981C',
	border: '#C5D0DE',
	light: '#F8FAFB',
	dark: '#333F52',
	heading: '#333F52',
	text: '#333F52',
	success: '#39B54A',
	warning: '#F7981C',
	error: '#F85359',
	info: '#1991EB'
}

const theme = {
	color
}

export default theme;
