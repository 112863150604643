import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding: 40px;
	background-color: dodgerblue;
`

class Default extends React.Component {
	render() {
		return (
			<Wrapper>
				DEFAULT
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				End
			</Wrapper>
		);
	}
}

export default Default;
