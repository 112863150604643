import React from 'react';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../store/actions';
import Modal from '../components/modal/Modal';

class AppModal extends React.Component {
	constructor(props) {
		super(props);
		
		this.openModal = this.openModal.bind(this);
	}
	
	openModal() {
		this.props.openModal();
	}
	
	render() {
		return (
			<div>
				<button onClick={this.openModal}>Open modal</button>
				
				<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
				1
				<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
				2
				<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
				3
				<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
				4
				<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
				5
				<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
				
				END
				
				{this.props.modal && <Modal modal={this.props.modal} closeModal={this.props.closeModal} />}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		modal: state.modal
	};
};

const mapDispatchToProps = {
	openModal,
	closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AppModal);
