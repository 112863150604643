import React, { useEffect, useRef } from 'react';
import NotificationSystem from 'react-notification-system';

const styling = {
	Containers: {
		tc: {
			margin: 0,
			width: '100%',
			maxWidth: '1320px',
			transform: 'translateX(-50%)'
		}
	},
	NotificationItem: {
		DefaultStyle: {
			border: 0,
			color: '#fff'
		},
		success: {
			backgroundColor: 'lightgreen'
		},
		error: {
			backgroundColor: 'crimson'
		},
		warning: {
			backgroundColor: 'wheat'
		},
		info: {
			backgroundColor: 'dodgerblue'
		}
	}
}

export let actions = null;

const Notification = () => {
	const ref = useRef(null);
	
	useEffect(() => {
		actions = ref.current;
	}, []);
	
	return <NotificationSystem style={styling} ref={ref} />
}

export default Notification;
