import styled, { css } from 'styled-components';
import { defaultStyles } from '../../components/hesehus-react-accordiontabs';

const buttonStyle = css`
	height: 40px;
	
	@media(min-width: ${props => props.breakpoint}) {
		padding: 0 30px;
	}
	
	${props => props.active && css`
		background-color: ${props => props.theme.color.primary};
		color: #fff;
	`}
`

const Tabs = styled(defaultStyles.Tabs)`
	background-color: #fff;
`

const Tab = styled(defaultStyles.Tab)`
	${buttonStyle};
`

const Accordion = styled(defaultStyles.Accordion)`
	${buttonStyle};
`

const Heading = styled(defaultStyles.Heading)`
	@media(min-width: ${props => props.breakpoint}) {}
`

const Icon = styled(defaultStyles.Icon)`
	margin-right: 0.5em;
	
	@media(min-width: ${props => props.breakpoint}) {}
`

const Panels = styled(defaultStyles.Panels)`
	@media(min-width: ${props => props.breakpoint}) {}
`

const Panel = styled(defaultStyles.Panel)`
	@media(min-width: ${props => props.breakpoint}) {}
`

const Content = styled(defaultStyles.Content)`
	@media(min-width: ${props => props.breakpoint}) {}
`

const styles = {
	Tabs,
	Tab,
	Accordion,
	Heading,
	Icon,
	Panels,
	Panel,
	Content
};

export default styles;
