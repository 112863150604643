import React from 'react';
import { Row, Column, Box } from '../styling/general';
import { formData, FormHelper, Field, Group, Input, Choice, ChoiceGroup, Select } from '../components/FormElements';

class Form extends React.PureComponent {
	
	
	constructor(props) {
		super(props);
		
		this.state = {
			errors: {}
		};
		
		this.validateField = this.validateField.bind(this);
		this.submitForm = this.submitForm.bind(this);
	}
	
	serverValidation() {
		const form = formData(document.forms.testform);
		let errors = {};
		
		if (form.firstname.length < 4) {
			errors.firstname = 'Minimum 4 tegn';
		}
		
		if (!form.lastname) {
			errors.lastname = 'Påkrævet';
		}
		
		if (!form.address) {
			errors.address = 'Påkrævet';
		}
		
		if (!form.phone) {
			errors.phone = 'Påkrævet';
		}
		
		if (!form.country) {
			errors.country = 'Påkrævet';
		}
		
		if (!form.gender) {
			errors.gender = 'Påkrævet';
		}
		
		if (!form.agreement) {
			errors.agreement = 'Påkrævet';
		}
		
		return errors;
	}
	
	validateForm() {
		let validated = true;
		let errors = this.serverValidation();
		
		if (Object.keys(errors).length > 0) {
			validated = false;
			
			if (JSON.stringify(errors) === JSON.stringify(this.state.errors)) {
				return validated;
			}
			
			this.setState({
				errors: errors
			});
		}
		
		return validated;
	}
	
	validateField(e) {
		// const target = e.target;
		// const errors = this.serverValidation();
		
		// this.setState(prevState => ({
		// 	errors: {
		// 		...prevState.errors,
		// 		[target.name]: errors[target.name] || false
		// 	}
		// }));
	}
	
	submitForm(e) {
		e.preventDefault();
		
		// const validated = this.validateForm();
		
		// if (!validated) {
		// 	return;
		// }
		
		const data = formData(document.forms.testform);
		
		console.log('SEND', data)
		
		// const body = {
		// 	firstName: data.firstname,
		// 	lastName: data.lastname,
		// 	address: data.address
		// }
		
		// console.log(body)
	}
	
	render() {
		console.log('render');
		return (
			<>
				<h2>Form</h2>
				
				<Box>
					<FormHelper
						id="testform"
						onSubmit={this.submitForm}
					>
						<Field
							type="text"
							name="user.firstname"
							label="Fornavn"
							onBlur={this.validateField}
							errors={this.state.errors}
						/>
						
						<Field
							type="select"
							name="country"
							label="Land"
							onBlur={this.validateField}
							errors={this.state.errors}
						>
							<option value="">-</option>
							<option value="DK">Danmark</option>
							<option value="GB">England</option>
						</Field>
						
						<Group
							type="radio"
							name="gender"
							label="Køn"
							onBlur={this.validateField}
							errors={this.state.errors}
						>
							<Field label="Mand" />
							<Field label="Kvinde" />
						</Group>
						
						<Group
							type="checkbox"
							name="test[]"
							label="Test"
						>
							<Field label="One" value="one" defaultChecked />
							<Field label="Two" value="two" />
							<Field label="Three" value="three" defaultChecked />
							<Field label="Four" value="four" />
						</Group>
						
						<Field
							type="checkbox"
							name="agreement"
							label="Jeg accepterer"
							onBlur={this.validateField}
							errors={this.state.errors}
						/>
						
						<button>Send</button>
					</FormHelper>
					
				</Box>
				
				{/* <Box>
					<form id="testform" onSubmit={this.submitForm}>
						<Row gutters={30}>
							<Column>
								<Input
									name="firstname"
									label="Fornavn"
									onBlur={this.validateField}
									errors={this.state.errors}
								/>
							</Column>
							
							<Column>
								<Input
									name="lastname"
									label="Efternavn"
									onBlur={this.validateField}
									errors={this.state.errors}
								/>
							</Column>
						</Row>
						
						<Input
							name="address"
							label="Adresse"
							onBlur={this.validateField}
							errors={this.state.errors}
						/>
						
						<Input
							name="phone"
							label="Telefon"
							placeholder="+45"
							onBlur={this.validateField}
							errors={this.state.errors}
						/>
						
						<Select
							name="country"
							label="Land"
							onBlur={this.validateField}
							errors={this.state.errors}
						>
							<option value=""></option>
							<option value="DK">Danmark</option>
							<option value="GB">England</option>
						</Select>
						
						<ChoiceGroup
							type="radio"
							name="gender"
							label="Køn"
							errors={this.state.errors}
						>
							<Choice
								label="Mand"
								value="male"
								onBlur={this.validateField}
								id="test1"
							/>
							
							<Choice
								label="Kvinde"
								value="female"
								onBlur={this.validateField}
								id="test2"
							/>
						</ChoiceGroup>
						
						<ChoiceGroup
							type="radio"
							name="subscription"
							label="Abonnement"
							errors={this.state.errors}
							box
						>
							<Choice
								label="1 måned"
								value="1"
								onBlur={this.validateField}
								id="subscription1"
							/>
							
							<Choice
								label="2 måneder"
								value="2"
								onBlur={this.validateField}
								id="subscription2"
							/>
						</ChoiceGroup>
						
						<ChoiceGroup
							type="checkbox"
							name="agreement"
							label="Betingelser"
							onBlur={this.validateField}
							errors={this.state.errors}
						>
							<Choice
								label="Jeg accepterer"
								value={true}
							/>
						</ChoiceGroup>
						
						<button>Send</button>
					</form>
				</Box> */}
			</>
		);
	}
}

export default Form;
