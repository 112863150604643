import React from 'react';
import ContentComponent from './components/Content';
import {withRouter} from 'react-router-dom';

class AccordionTabs extends React.Component {
	constructor(props) {
		super(props);
		
		// find item with "active" prop, and set it as the active item
		const activeItem = this.props.items.findIndex(item => item.active);
		
		this.state = {
			activeItem: activeItem
		};
	}
	
	toggleItem(i, item, e) {
		if (item.path) {
			window.history.replaceState('', '', item.path);
		}
		
		if (e) {
			// toggle accordion open/close
			const target = e.currentTarget;
			const activeItem = this.state.activeItem === i ? '' : i;
			
			// scroll to active
			this.setState({activeItem: activeItem}, () => {
				window.scrollTo({
					top: target.offsetTop,
					behavior: 'smooth'
				});
			});
		}
		else {
			this.setState({activeItem: i});
		}
	}
	
	render() {
		const {Wrapper, Tabs, Tab, Panels, Panel, Accordion, Icon, Heading, Arrow} = this.props.styles;
		const {items, breakpoint} = this.props;
		const {activeItem} = this.state;
		
		// map tabs, and panels with accordion and content
		return (
			<Wrapper>
				<Tabs breakpoint={breakpoint}>
					{items.map((item, i) => {
						const active = activeItem === i;
						
						return (
							<Tab
								key={i}
								active={active}
								onClick={e => {item.content && this.toggleItem(i, item); item.onClick && item.onClick()}}
								breakpoint={breakpoint}
								href={item.href}
								to={item.to}
							>
								{item.icon && <Icon src={item.icon} alt="" />}
								{item.heading && <Heading>{item.heading}</Heading>}
							</Tab>
						);
					})}
				</Tabs>
				
				<Panels breakpoint={breakpoint}>
					{items.map((item, i) => {
						const active = activeItem === i;
						
						return (
							<Panel key={i}>
								<Accordion
									active={active}
									onClick={e => { item.content && this.toggleItem(i, item, e); item.onClick && item.onClick() }}
									breakpoint={breakpoint}
									href={item.href}
									to={item.to}
								>
									{item.icon && <Icon src={item.icon} alt="" />}
									{item.heading && <Heading>{item.heading}</Heading>}
									{item.content && <Arrow active={active} breakpoint={breakpoint} />}
								</Accordion>
								
								{item.content && <ContentComponent {...this.props} {...item} active={active} />}
							</Panel>
						);
					})}
				</Panels>
			</Wrapper>
		)
	}
}

export default withRouter(AccordionTabs);
