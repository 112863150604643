import styled, { css, keyframes, createGlobalStyle } from 'styled-components';

// TODO: remove
const MediaSizes = {
	lg: '1024px'
}

const GlobalStyle = createGlobalStyle`
	.slick-slider.pdp-slider {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		flex-wrap: ${props => props.position === 'bottom' ? 'wrap' : 'nowrap' };
	}
	
	.pdp-slider .slick-list {
		width: calc(100% - 100px);
		order: 1;
	}
`

const Wrapper = styled.div``

const Slide = styled.div`
	outline: 0;
	display: flex !important;
	align-items: center;
	justify-content: center;
	
	img {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
		flex-shrink: 0;
		cursor: zoom-in;
	}
`

const SlideArrow = styled.button`
	padding: 0;
	width: 50px;
	height: 50px;
	background-color: transparent;
	border: 0;
	${'' /* position: absolute;
	top: 50%;
	transform: translateY(-50%); */}
	display: flex !important;
	align-items: center;
	justify-content: center;
	order: 1;
	${'' /* z-index: 1; */}
	cursor: pointer;
	
	${props => props.prev ? css`
		left: -20px;
		&:before { transform: translateX(4px) rotate(135deg); }
	` : css`
		right: -20px;
		&:before { transform: translateX(-4px) rotate(-45deg); }
	`}
	
	&:before {
		content: "";
		width: 14px;
		height: 14px;
		border-right: 1px solid #000;
		border-bottom: 1px solid #000;
		display: block;
		
		@media(min-width: ${MediaSizes.lg}) {
			width: 20px;
			height: 20px;
		}
	}
	
	&:focus {
		outline: 0;
	}
`

const Pagination = styled.ul`
	display: flex;
	justify-content: center;
	
	${props => props.position === 'bottom' ? css`
		width: 100%;
		order: 1;
	` : css`
		flex-direction: column;
		order: 0;
	`};
`

const Dot = styled.div`
	margin: 10px;
	width: 10px;
	height: 10px;
	border: 1px solid #ccc;
	border-radius: 50%;
	
	@media(min-width: ${MediaSizes.lg}) {
		width: 150px;
		height: 100px;
		background-image: ${props => props.image ? 'url(' + props.image + ')' : 'none'};
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		border: 0;
		border-radius: 0;
		display: ${props => props.hideDotOnDesktop ? 'none' : 'block'};
		cursor: pointer;
		opacity: 0.2;
	}
	
	.slick-active & {
		background-color: #ccc;
		
		@media(min-width: ${MediaSizes.lg}) {
			background-color: transparent;
			opacity: 1;
		}
	}
`

const ShowMore = styled.button`
	display: none;
	
	@media(min-width: ${MediaSizes.lg}) {
		margin: 10px;
		padding: 0;
		width: 150px;
		height: 100px;
		background-color: black;
		border: 0;
		border-radius: 0;
		display: block;
		font-size: 11px;
		text-align: center;
		text-transform: uppercase;
		appearance: none;
		cursor: pointer;
		
		&:before {
			content: "+";
			display: block;
			font-size: 30px;
		}
		
		&:focus {
			outline: 0;
		}
	}
`

const spin = keyframes`
	0% { transform: translate(-50%, -50%) rotate(0deg); }
	100% { transform: translate(-50%, -50%) rotate(360deg); }
`

const Spinner = styled.div`
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 3px solid rgba(0,0,0, 0.2);
	border-top-color: rgba(0,0,0, 0.8);
	border-radius: 50%;
	animation: ${spin} 1s linear infinite;
`

const styles = {
	Wrapper: Wrapper,
	Spinner: Spinner,
	Slide: Slide,
	SlideArrow: SlideArrow,
	Pagination: Pagination,
	Dot: Dot,
	ShowMore: ShowMore,
	GlobalStyle: GlobalStyle
};

export default styles;
