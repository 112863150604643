import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import theme from './theme';
import fix from './css/fix';
import base from './css/base';

const GlobalStyle = createGlobalStyle`
	${fix}
	${base}
`

class Main extends React.Component {
	render() {
		return (
			<ThemeProvider theme={theme}>
				<>
					<GlobalStyle />
					{this.props.children}
				</>
			</ThemeProvider>
		);
	}
}

export default Main;
