import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Header = styled.header`
	width: 100%;
	height: 80px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0,0,0, 0.2);
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	
	&.header-fixed {
		position: fixed;
		transform: translateY(-100%);
		
		&.header-visible {
			transform: translateY(0);
		}
	}
	
	+ main {
		margin-top: 80px;
	}
	
	a {
		padding: 0 20px;
		display: flex;
		align-items: center;
		transition: 200ms;
		
		&:hover {
			background-color: ${props => props.theme.color.light};
		}
	}
`

const Link = styled(NavLink)`
	&.active {
		color: ${props => props.theme.color.primary};
	}
`

class HeaderClass extends React.Component {
	constructor(props) {
		super(props);
		
		this.prevScrollY = 0;
		this.headerRef = React.createRef();
		this.handleScroll = this.handleScroll.bind(this);
	}
	
	componentDidMount() {
		this.prevScrollY = window.pageYOffset;
		window.addEventListener('scroll', this.handleScroll);
	}
	
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}
	
	handleScroll(e) {
		var scrollY = window.pageYOffset;
		var headerClass = this.headerRef.current.classList;
		
		if (scrollY === 0) {
			// top
			headerClass.remove('header-fixed', 'header-visible');
		}
		else if (scrollY > 60) {
			if (scrollY < this.prevScrollY) {
				// up
				headerClass.add('header-visible');
			}
			else {
				// down
				headerClass.add('header-fixed');
				headerClass.remove('header-visible');
			}
		}
		
		this.prevScrollY = scrollY;
	}
	
	render() {
		return (
			<Header ref={this.headerRef}>
				<Link to="/" exact activeClassName="active">Home</Link>
				<Link to="/accordiontabs" activeClassName="active">AccordionTabs</Link>
				<Link to="/modal" activeClassName="active">Modal</Link>
				<Link to="/fetch" activeClassName="active">Fetch</Link>
				<Link to="/rss" activeClassName="active">RSS</Link>
				<Link to="/datagrid" activeClassName="active">DataGrid</Link>
				<Link to="/form" activeClassName="active">Form</Link>
				<Link to="/media" activeClassName="active">Media</Link>
				<Link to="/hero" activeClassName="active">Hero</Link>
			</Header>
		);
	}
}

export default HeaderClass;
