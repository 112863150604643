import React from 'react';
import AccordionTabs from './AccordionTabs';
import defaultStyles from './styles';

export default class Wrapper extends React.Component {
	render() {
		const { styles = {} } = this.props
		
		return (
			<AccordionTabs
				{...this.props}
				styles={{...defaultStyles, ...styles}}
			/>
		);
	}
}
