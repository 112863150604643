import React from 'react';

class Home extends React.Component {
	constructor(props) {
		super(props);
		
		this.createDB = this.createDB.bind(this);
		this.deleteDB = this.deleteDB.bind(this);
		this.read = this.read.bind(this);
		this.write = this.write.bind(this);
		
		// https://developers.google.com/web/ilt/pwa/working-with-indexeddb
		// console.log('indexedDB' in window);
		
		this.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
		
		// this.deleteDB('zizzi');
		// this.createDB('zizzi');
	}
	
	createDB() {
		var request = this.indexedDB.open('zizzi', 1);
		
		request.onupgradeneeded = event => {
			console.log('upgrade')
			var db = event.target.result;
			
			if (db.objectStoreNames.contains('products')) {
				console.log('delete')
				db.deleteObjectStore('products');
			}
			else {
				console.log('create')
				db.createObjectStore('products', {keyPath: 'path'});
			}
		};
		
		request.onsuccess = event => {
			var db = event.target.result;
			
			this.read(db);
		};
		
		request.onerror = event => {
			console.error('Database error', event.target.errorCode)
		};
	}
	
	read(db) {
		var request = db.transaction('products', 'readonly')
		.objectStore('products')
		.get('/test');
		
		request.onsuccess = event => {
			if (event.target.result) {
				console.log(event.target.result.data);
			}
			else {
				console.log('No data record');
				this.write(db);
			}
		};
		
		request.onerror = event => {
			console.log('Transaction failed');
		};
	}
	
	write(db) {
		var request = db.transaction('products', 'readwrite')
		.objectStore('products')
		.put({
			path: '/test',
			data: '<div>Test</div>'
		});

		request.onsuccess = event => {
			console.log('The data has been written successfully');
		};

		request.onerror = event => {
			console.log('The data has been written failed');
		}
	}
	
	deleteDB(name) {
		var request = this.indexedDB.deleteDatabase(name);
		
		request.onsuccess = () => {
			console.log("Deleted database successfully");
		};
		request.onerror = () => {
			console.log("Couldn't delete database");
		};
		request.onblocked = () => {
			console.log("Couldn't delete database due to the operation being blocked");
		};
	}
	
	render() {
		return (
			<>
				HOME
			</>
		);
	}
}

export default Home;
