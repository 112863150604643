import React from 'react';
import { Box } from '../styling/general';
import Gallery from '../components/Gallery';

/*
	- video element (html in slide)
	- callbacks
	- methods (set specific image, slide to index, open image in fullscreen)
*/

class Media extends React.Component {
	render() {
		const images = [
			{
				src: 'https://dummyimage.com/600x400/84f/fff.png',
				thumbnail: 'https://dummyimage.com/150x100/84f/fff.png',
				title: 'Title 1',
				w: 600,
				h: 400
			},
			{
				src: 'https://dummyimage.com/600x400/f48/fff.png',
				thumbnail: 'https://dummyimage.com/150x100/f48/fff.png',
				title: 'Title 2',
				w: 600,
				h: 400
			},
			{
				src: 'https://dummyimage.com/600x400/f84/fff.png',
				thumbnail: 'https://dummyimage.com/150x100/f84/fff.png',
				title: 'Title 3',
				w: 600,
				h: 400
			},
			{
				src: 'https://dummyimage.com/600x400/8f4/fff.png',
				thumbnail: 'https://dummyimage.com/150x100/8f4/fff.png',
				title: 'Title 4',
				w: 600,
				h: 400
			},
		];
		
		return (
			<>
				<h2>Gallery</h2>
				<Box>
					<Gallery images={images} maxThumbs={3} position="left" />
				</Box>
			</>
		);
	}
}

export default Media;