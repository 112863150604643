import React from 'react';
import Gallery from './Gallery';
import defaultStyles from './styles';

let subComponents = {
	Gallery
};

let styles = defaultStyles;

class Wrapper extends React.Component {
	constructor(props) {
		super(props);
		
		if (props.subComponents) {
			subComponents = { ...subComponents, ...props.subComponents };
		}
		
		if (props.styles) {
			styles = { ...styles, ...props.styles };
		}
	}
	
	render() {
		const { Gallery } = subComponents;
		const { images, maxThumbs, position } = this.props;
		
		return (
			<Gallery
				subComponents={subComponents}
				styles={styles}
				images={images}
				maxThumbs={maxThumbs}
				position={position}
			/>
		);
	}
}

export default Wrapper;
