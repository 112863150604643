import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Header from './Header';
import Notification from './Notification';

const Main = styled.main`
	margin: 0 auto;
	padding: 50px;
	max-width: 1400px;
`;

class App extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Header />
				<Main>{this.props.children}</Main>
				<Notification />
			</React.Fragment>
		);
	}
}

export default withRouter(App);
