import React from 'react';
import styled from 'styled-components';

import { actions as notification } from '../Notification';

import kitten from '../assets/kitten.jpg';
import bear from '../assets/bear.jpg';

const Wrapper = styled.div`
	margin-left: -50vw;
	width: 100vw;
	height: 800px;
	background-color: wheat;
	display: flex;
	position: relative;
	left: 50%;
	overflow: hidden;
	
	&:hover {
		h1 {
			opacity: 0.5;
		}
	}
`;

const Slide = styled.div`
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	align-items: center;
	background: center / cover no-repeat;
	background-image: url(${props => props.image});
	transition: 600ms;
	position: relative;
	
	&:first-child h1 {
		left: auto;
		right: 0;
	}
	
	&:before {
		content: "";
		width: 100%;
		height: 100%;
		background-color: ${props => props.color};
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: inherit;
	}
	
	&:hover {
		flex-grow: 2;
		
		h1 {
			opacity: 1;
		}
		
		&:before {
			opacity: 0.5;
		}
	}
`;

const Heading = styled.h1`
	color: #fff;
	font-size: 200px;
	text-transform: uppercase;
	position: absolute;
	left: 0;
	transition: inherit;
	user-select: none;
`;

class Kansas extends React.Component {
	addNotification() {
		notification.addNotification({
			title: 'This is a title',
			message: 'Message',
			level: 'success',
			position: 'tc',
			autoDismiss: 0
		});
	};

	render() {
		return (
			<>
				<Wrapper>
					<Slide image={kitten} color="red">
						<Heading>Kitty</Heading>
					</Slide>
					
					<Slide image={bear} color="blue">
						<Heading>Bear</Heading>
					</Slide>
				</Wrapper>
				
				{/* <button onClick={this.addNotification}>Add notification</button> */}
			</>
		);
	}
}

export default Kansas;
