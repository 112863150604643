import styled, { css } from 'styled-components';

export const Column = styled.div`
	flex: 1;
`

export const Row = styled.div`
	display: flex;
	
	${props => props.gutters && css`
		margin-left: -${props.gutters}px;
		
		${Column} {
			margin-left: ${props.gutters}px;
		}
	`};
`

export const Box = styled.div`
	padding: 40px;
	margin: 40px auto;
	background-color: #fff;
	box-shadow: 0 2px 4px rgba(0,0,0, 0.1);
`
